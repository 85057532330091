import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import emailicon from '../../../images/email.png';
import twittericon from '../../../images/twitter.png';
import whatsappIcon from '../../../images/whatspp.png';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
import about from '../../../images/banner01.png';
import privacy from '../../../images/privcay01.png';
import Arrow from './Arrow';
import Header from '../home/Header';
import Footer from '../home/Footer';


const Posh = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <Header />

                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '75px 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${about})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        ></div>
                        {/* <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgb(255 255 255 / 33%)',
                                zIndex: 2,
                            }}
                        ></div>
                        <Container fluid style={{ position: 'relative', zIndex: 3 }}>
                            <Breadcrumb className="breadcrumb mt-5">
                                <h1 style={{ color: '#ffffff', textDecoration: 'none', top: 29 }}>
                                    Contact <span style={{ color: '#2671ae' }}>Us</span>
                                </h1>
                            </Breadcrumb>
                        </Container> */}
                    </div>

                    <section className='about'>
                        <Container className='mt-3 mb-5'>
                            <div className='text-left'>
                                <p className='breadcrumb-text'>
                                    <span>Home</span>
                                    <span style={{ padding: '0 8px' }}>{">"}</span>
                                    <span style={{ fontWeight: '600' }}>POSH</span>
                                </p>
                                <p className='breadcrumb-title'>POS<span style={{ color: '#2671ae' }}>H</span></p>
                            </div>
                            <Row className="justify-content-center mt-4">
                                <Col md={12} className="d-flex mt-2">
                                    <div>
                                        <h5> KL India Policy on Prevention of Sexual Harassment (POSH) of women at workplace</h5>
                                        <p className='aboutpara'>Kinlong Hardware India Private Limited has a zero-tolerance policy for any act of sexual harassment of any kind and any act of suppressing or distortion of such facts or evidence and retaliation. Kinlong Hardware India Private Limited is committed to take all necessary steps in ensuring that its staff works in a safe and respectful environment that is conducive to their professional growth and promotes equality of opportunity.</p>
                                        <p className='aboutpara'>This policy applies to all employees (full-time, part-time, trainees and those on contractual assignments) of Kinlong Hardware India Private Limited, including all subsidiaries and affiliated companies at their workplace or at client sites. The Company will not tolerate sexual harassment, if engaged in by clients or by suppliers or any other business associates.</p>
                                        <p className='aboutpara'><b>The workplace includes:</b></p>
                                    </div>
                                </Col>
                            </Row>
                            <p className='aboutpara'>All offices or other premises where Kinlong’s business is conducted. All company-related activities are performed at any other site away from the Company’s premises. Any social, business, Team Outing, Meeting or other functions where the conduct or comments may have an adverse impact on the workplace or workplace relations. Sexual harassment may be one or a series of incidents involving unsolicited and unwelcome sexual advances, requests for sexual favors, or any other verbal or physical conduct of sexual nature.</p>
                            <p className='aboutpara'><b>Sexual Harassment at the workplace includes:</b></p>
                            <p className='aboutpara'>a) Unwelcome sexual advances (verbal, written, or physical, Demand or request for sexual favors, Bad touch)</p>
                            <p className='aboutpara'>b) Any other type of sexually oriented conduct, </p>
                            <p className='aboutpara'>c) Verbal abuse or ‘joking’ that is sex-oriented,</p>
                            <p className='aboutpara'>d) any conduct that has the purpose or the effect of interfering with an
                                individual’s work performance or creating an intimidating, hostile or offensive
                                work environment and/or submission to such conduct is either an explicit or
                                implicit term or condition of employment and /or submission or rejection of
                                the conduct is used as a basis for making employment decisions. </p>
                            <p className='aboutpara'>e)Inappropriate conduct could also be a joke, a prank or even a compliment.
                                These gestures also lead to harassment although the intention of the individual
                                might not be to offend the other person.</p>
                        </Container>
                    </section>

                    <section>
                        <Container>
                            <h5>RESPONSIBILITIES REGARDING SEXUAL HARASSMENT:</h5>
                            <p className='aboutpara'>a)All employees of the Company have a personal responsibility to ensure that their behavior is not contrary to this policy.</p>
                            <p className='aboutpara'>b)All employees are encouraged to reinforce the maintenance of a work environment free from sexual harassment.</p>
                            <p className='aboutpara'><b>Policy Statement: -</b>The Prevention of Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 read along with its Rules of 2013, (the “Act”) states that no employee shall be subject to Sexual Harassment at any Workplace.</p>
                            <p className='aboutpara'><b>POSH Committee: -</b>Kinlong India will be <b>constituted</b> by an order of <b><span style={{ color: '#e90909' }}>I</span>nternal <span style={{ color: '#e90909' }}>C</span>omplaint <span style={{ color: '#e90909' }}>C</span>ommittee </b>(ICC) under Sexual Harassment at Workplace (Prevention, Prohibition and Redressal) Act, 2013 preferably headed by a woman member of the organization. Internal Complaints Committee (Henceforth known as ‘committee’).</p>
                            <p className='aboutpara'>So we have formed an ICC as per the act and below are the committee members identified along with an External Committee member and their contact information, Mail ID updated for all employees reference.</p>
                        </Container>
                    </section>

                    <section className='table'>
                        <Container className='mt-5'>
                            <h5>POSH Committee Members</h5>
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ color: '#fff' }}>S.NO</th>
                                            <th style={{ color: '#fff' }}>Emp.No</th>
                                            <th style={{ color: '#fff' }}>Employee Name</th>
                                            <th style={{ color: '#fff' }}>Location</th>
                                            <th style={{ color: '#fff' }}>Designations</th>
                                            <th style={{ color: '#fff' }}>E-Mail ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>224</td>
                                            <td>MS.SKAKTHI SHREE D</td>
                                            <td>BANGALORE</td>
                                            <td>Presiding Officer (Chair Person)</td>
                                            <td>shree.skakthi@kinlong.cn</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>223</td>
                                            <td>MS.SHREEKALA T</td>
                                            <td>BANGALORE</td>
                                            <td>Emp.Member</td>
                                            <td>t.shreekala@kinlong.cn</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>432</td>
                                            <td>MS.SHREENIDHI NAYAK</td>
                                            <td>BANGALORE</td>
                                            <td>Officer(Administration)</td>
                                            <td>nayak.shreenidhi@kinlong.cn	</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>083</td>
                                            <td>MR. PRAVEEN DEVADIGA</td>
                                            <td>BANGALORE</td>
                                            <td>Emp.Member</td>
                                            <td>inhr@kinlong.com</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>455</td>
                                            <td>MS.ARYA MOHAN</td>
                                            <td>KOCHIN</td>
                                            <td>Emp.Member</td>
                                            <td>mohan.arya@kinlong.cn</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>174</td>
                                            <td>MS.NISHA</td>
                                            <td>GURGAON</td>
                                            <td>Emp.Member</td>
                                            <td>rattan.nisha@kinlong.cn</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>409</td>
                                            <td>MS.PRERNA</td>
                                            <td>GURGAON</td>
                                            <td>Emp.Member</td>
                                            <td>sign.prerna@kinlong.cn</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>299</td>
                                            <td>MS.NEHA SUNIL MISHRA</td>
                                            <td>MUMBAI</td>
                                            <td>Emp.Member</td>
                                            <td>mishra.neha@kinlong.cn</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>076</td>
                                            <td>MR.SAUGANDH RADHAKRISHNAN</td>
                                            <td>MUMBAI</td>
                                            <td>Emp.Member</td>
                                            <td>saugandh@kinlong.cn</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>NA</td>
                                            <td style={{ color: '#e60a0a' }}>MR.GANESH POOJARY</td>
                                            <td style={{ color: '#e60a0a' }}>BLR - External</td>
                                            <td style={{ color: '#e60a0a' }}>External Member</td>
                                            <td>ganesh@kgpaiandco.com</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Container>
                    </section>


                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>
                    <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/in/kinlong-india-766b98198" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>
                    </div>

                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>

                </>
            )}
        </div>
    );
};

export default Posh;
