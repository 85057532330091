import React from 'react';
import AppRouter from './pages/base/config/router'; 
import './App.css'; 

function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
