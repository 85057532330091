import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaPhone, FaEnvelope, FaArrowRight } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../home/Header';
import Footer from '../home/Footer';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import dc1 from '../../../images/dc01.png';
import dc2 from '../../../images/dc02.png';
import dc3 from '../../../images/dc03.png';
import dc4 from '../../../images/dc04.png';
import dc5 from '../../../images/dc05.png';
import West from '../../../images/west01.png';
import LT from '../../../images/lt01.png';
import flag from '../../../images/indianflag.png';
import emailicon from '../../../images/email.png';
import twittericon from '../../../images/twitter.png';
import footerLogo from '../../../images/kinlongfooter01.png';
import whatsappIcon from '../../../images/whatspp.png';
import about from '../../../images/ab01.jpg';
import Arrow from './Arrow';

const DoorControl = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const services = [
        { title: 'Top Clip/KMJ44330', subtitle: 'Top clip/KMJ44330/Cover Plate is 304/Satin+Mirror/10-12MM', imgSrc: dc1 },
        { title: 'Glass Door Lock/KMS11250', subtitle: 'Lock clip/KMS11250/Old code S110A/304/Satin/10-12MM', imgSrc: dc2 },
        { title: 'Glass Door Lock/KMS11230', subtitle: 'Lock clip/KMS11230/Old code S101/304/Satin/12 Year /10-12MM', imgSrc: dc3 },
        { title: 'H-Type Handle/KLS10204', subtitle: 'Handle/KLS10204/Old code KLS102-455/Φ25X0.8MM/304/Satin/10-50MM', imgSrc: dc4 },
        { title: 'Floor Spring/HD102', subtitle: 'Floor spring/HD102/6°/Cover plate 304/wire drawing/With device', imgSrc: dc5 },
    ];
    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>

                    <Header />

                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '50px 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${about})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        >
                        </div>

                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgb(255 255 255 / 33%)',
                                zIndex: 2,
                            }}
                        ></div>

                        <Container fluid style={{ position: 'relative', zIndex: 3 }}>
                            <Breadcrumb className="breadcrumb mt-5">
                                <h1 style={{ color: '#ffffff', textDecoration: 'none', top: 29 }}>Door <span style={{ color: '#2671ae' }}>Control</span></h1>
                            </Breadcrumb>
                        </Container>

                    </div>
                    <section className="service-section pt_70 pb_120 mt-5">
                        <Container fluid>
                            <Row>
                                {/* Sidebar */}
                                <Col lg={4} md={12}>
                                    <Card style={{ textAlign: 'center', borderRadius: '2em', boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)' }}>
                                        <div className="service-sidebar mr_40">
                                            <div className="category-widget mb_40">
                                                <ul className="category-list clearfix" style={{ padding: '20px' }}>
                                                    <li><Link to="/products" >Door & Window </Link></li>
                                                    <li className="mt-3"><Link to="/doorandcontrol" className="current">Door Control <FaArrowRight /></Link></li>
                                                    <li className="mt-3"><Link to="/interiorproducts">Interior Products </Link></li>
                                                    <li className="mt-3"><Link to="/facade">Facade </Link></li>
                                                    <li className="mt-3"><Link to="/residentialprojects">Glass Railings </Link></li>
                                                    <li className="mt-3"><Link to="/residentialprojects">Commercial Products  </Link></li>
                                                    <li className="mt-3"><Link to="/residentialprojects">Steel Structure </Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                                {/* Content Area */}
                                <Col lg={8} md={12}>
                                    <div className="service-details-content">
                                        <Row>
                                            {services.map((service, index) => (
                                                <Col lg={4} md={4} sm={12} className="category-block" key={index}>
                                                    <Card className="service-block-one">
                                                        <div className="inner-box">
                                                            <figure className="image-box image-hov-two">
                                                                <img src={service.imgSrc} alt={service.title} />
                                                                <div className="overlay-image">
                                                                    <div className="lower-content">
                                                                        <h3>{service.title}</h3>
                                                                        <p className="subtitle-text">{service.subtitle}</p>
                                                                    </div>
                                                                </div>
                                                            </figure>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>



                    {/* WhatsApp Icon */}
                    <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990182', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a>
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>
                    <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/in/kinlong-india-766b98198" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                    </div>
                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>
                </>
            )}
        </div>
    );
};

export default DoorControl;
