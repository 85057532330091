import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import emailicon from '../../../images/email.png';
import twittericon from '../../../images/twitter.png';
import whatsappIcon from '../../../images/whatspp.png';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
import about from '../../../images/banner01.png';
import privacy from '../../../images/privcay01.png';
import Arrow from './Arrow';
import Header from '../home/Header';
import Footer from '../home/Footer';


const PrivacyPolicy = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <Header />

                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '75px 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${about})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        ></div>
                        {/* <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgb(255 255 255 / 33%)',
                                zIndex: 2,
                            }}
                        ></div>
                        <Container fluid style={{ position: 'relative', zIndex: 3 }}>
                            <Breadcrumb className="breadcrumb mt-5">
                                <h1 style={{ color: '#ffffff', textDecoration: 'none', top: 29 }}>
                                    Contact <span style={{ color: '#2671ae' }}>Us</span>
                                </h1>
                            </Breadcrumb>
                        </Container> */}
                    </div>
                    <section className='about'>
                        <Container className='mt-3 mb-3'>
                            <div className='text-left'>
                                <p className='breadcrumb-text'>
                                    <span>Home</span>
                                    <span style={{ padding: '0 8px' }}>{">"}</span>
                                    <span style={{ fontWeight: '600' }}>Privacy Policy</span>
                                </p>
                                <p className='breadcrumb-title'>Privacy <span style={{ color: '#2671ae' }}>Policy</span></p>
                            </div>
                            <Row>
                                <Col md={7} className="d-flex mt-3">
                                    <div>
                                        <p className='aboutpara'>At Kin Long, we care about the privacy of our visitors, and we are committed to always protecting your privacy and data. All the users must read and understand this Privacy Policy as it has been formulated to safeguard the user’s privacy. This Privacy Policy also outlines the ways the users can ensure protection of their personal identifiable information. You must accept the contents of this Policy to use or continue using our site. This Privacy Policy detailed herein is also applicable to user of the site or mobile application through mobile or any other similar device.</p>
                                        <p className='aboutpara'>We do not collect any data or your personal information, discreetly. Wherever, requisite information about your demographics or preference is needed, the same is collected through fillable data entry fields available on the website. It is not our policy to collect any personal data without first advising you of this. You will always know when you are providing personal data to us. For example, if you fill in a form or make an order from this website, we will need to take certain information from you in order to complete the requisite details and process your order.</p>
                                    </div>
                                </Col>

                                <Col md={5}>
                                    <img src={privacy} alt="Right Side" style={{ borderRadius: '10px' }} />
                                </Col>
                            </Row>
                            <p className='aboutpara'>We will use any data that you provide to us to notify you of offers or products that may be of interest to you but at no time will we provide your data to third parties for their marketing activities. In case you wish to stop these alerts, you may simply opt out by writing back to us of your desire to not be included in the mailing list.</p>
                            <p className='aboutpara'>The security of all information that we collect, and store is critical, and we observe strict data security guidelines to make sure that all data is stored and processed securely. If you have any questions or queries regarding our privacy policy use and storage of data, please do not hesitate to contact us by email at <a href="mailto:inhr@kinlong.com" style={{ color: '#225287' }}>inhr@kinlong.com.</a></p>
                            <p className='aboutpara'><b>Changes in Privacy Policy:</b></p>
                            <p className='aboutpara'>The policy may change from time to time, so users are requested to check it periodically.</p>
                        </Container>
                    </section>




                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>
                    <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/in/kinlong-india-766b98198" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>
                    </div>

                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>

                </>
            )}
        </div>
    );
};

export default PrivacyPolicy;
