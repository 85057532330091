import React from 'react';
import UPARROW from '../../../images/uparrow01.png';

interface UpArrowProps {
  onClick: () => void;
}

const UpArrow: React.FC<UpArrowProps> = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50px',
      height: '50px',
      borderRadius: '30px',
      cursor: 'pointer',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}
  >
    <img
      src={UPARROW} 
      alt="Up Arrow" 
      style={{ width: '50px', height: '40px' }}
    />
  </div>
);

export default UpArrow;
