import axios from 'axios'
import qs from 'query-string';
import moment from 'moment';

const httpService = axios.create({
    timeout: 60000,
})
// request interceptor
httpService.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// response interceptor
httpService.interceptors.response.use(res => res, err => {
    if (err.response && err.response.data) {
        return Promise.reject(err.response.data)
    } else {
        return Promise.reject(err)
    }
})

export const get = (url: string, params: any = {}) => {
    return new Promise<any>((resolve, reject) => {
        getSimpleness(url, params,).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
};

export const getSimpleness = (url: string, params: any = {}) => {
    return new Promise<any>((resolve, reject) => {
        axios.get(url, params)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    }).catch(error => Promise.reject(error))
};

export const post = (url: string, params: any = {}, headers?: any) => {
    return new Promise<any>((resolve, reject) => {
        postSimpleness(url, params, headers).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
};

export const postSimpleness = (url: string, params: any = {}, headers?: any) => {
    return new Promise<any>((resolve, reject) => {
        postBasics(url, params, headers).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export const postBasics = (url: string, params: any = {}, headers?: any) => {
    var timestamp = moment().format('x');
    if (!headers) {
        headers = {
            'Content-Type': 'application/json',
            Accept: '*/*',
        }
        params = qs.stringify(params)
    }
    headers.timestamp = timestamp;

    return new Promise<any>((resolve, reject) => {
        axios.post(url, params, { headers })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    }).catch(error => Promise.reject(error))
}


