import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Form, Button, Carousel, Card, Spinner } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaPhone, FaEnvelope } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../home/Header';
import Footer from '../home/Footer';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import contact from '../../../images/bg01.jpg';
import whatsappIcon from '../../../images/whatspp.png';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
import emailicon from '../../../images/email.png';
import twittericon from '../../../images/twitter.png';
// import about from '../../../images/ab01.jpg';
import about from '../../../images/banner01.png';
import Arrow from './Arrow';
import axios from 'axios';
import api from '../config/api';
// import { post } from '../../../utils/HttpService';

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [spinnerFlag, setSpinnerFlag] = useState(false);
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState<string | null>(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSpinnerFlag(true);
    
        const trimmedName = contactName.trim();
        const trimmedEmail = email.trim();
        const trimmedPhone = phoneNumber.trim();
        const trimmedMessage = message.trim();
    
        // Email validation
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail);
        if (!isValidEmail) {
            setResponseMessage('Please enter a valid email address.');
            setSpinnerFlag(false);
            return;
        }
    
        // Prepare the payload
        const payload = {
            user_name: trimmedName, 
            user_email: trimmedEmail, 
            phone: trimmedPhone, 
            message: trimmedMessage
        };
    
        // API call
        axios.post(api.api_enquiry_submitMessage_uat, payload, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            if (res.status === 200 && res.data.message === "Your enquiry has been submitted successfully.") {
                setResponseMessage('Message submitted successfully!');
                setMessage('');
                setContactName('');
                setPhoneNumber('');
                setEmail('');
            } else {
                setResponseMessage('Failed to submit message. Please try again.');
            }
        })
        .catch(err => {
            console.error("Error details:", err.response?.data || err.message || err);
            const errorDetails = err.response?.data?.errors || {};
            setResponseMessage(
                err.response?.data?.message || 
                'An error occurred. Details: ' + JSON.stringify(errorDetails)
            );
        })
        .finally(() => {
            setSpinnerFlag(false);
        });
    };
    
    
        
    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>

                    <Header />

                    {/* Breadcrumb */}
                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '75px 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${about})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        ></div>
                        {/* <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgb(255 255 255 / 33%)',
                                zIndex: 2,
                            }}
                        ></div>
                        <Container fluid style={{ position: 'relative', zIndex: 3 }}>
                            <Breadcrumb className="breadcrumb mt-5">
                                <h1 style={{ color: '#ffffff', textDecoration: 'none', top: 29 }}>
                                    Contact <span style={{ color: '#2671ae' }}>Us</span>
                                </h1>
                            </Breadcrumb>
                        </Container> */}
                    </div>
                    {/* Enquiry Section */}
                    <section className="enquiry mt-5">
                        <Container>
                            <Card style={{ textAlign: 'center', borderRadius: '2em', boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)' }}>
                                <Card.Body>
                                    <Row className="align-items-center">
                                        <Col md={6} className="form-section form-box-shadow">
                                            <h2 className="mt-3">Enquir<span style={{ color: '#2671ae' }}>y</span></h2>
                                            <Form className="mb-3 ps-4" onSubmit={handleSubmit}>
                                                <Form.Group controlId="formName" className="mb-3">
                                                    <Form.Label className="text-start w-100">
                                                        Name <span style={{ color: 'red' }}>*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter your name"
                                                        value={contactName}
                                                        onChange={(e) => setContactName(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="formEmail" className="mb-3">
                                                    <Form.Label className="text-start w-100">
                                                        Email <span style={{ color: 'red' }}>*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter your email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="formPhone" className="mb-3">
                                                    <Form.Label className="text-start w-100">
                                                        Phone <span style={{ color: 'red' }}>*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="tel"
                                                        placeholder="Enter your phone number"
                                                        value={phoneNumber}
                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="formMessage" className="mb-3">
                                                    <Form.Label className="text-start w-100">Message</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        placeholder="Your message"
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    />
                                                </Form.Group>
                                                {spinnerFlag ?
                                                    <Spinner animation="border" role="status" variant='primary'></Spinner>
                                                    :
                                                    <Button variant="primary" type="submit">
                                                        Submit
                                                    </Button>
                                                }
                                            </Form>
                                            {responseMessage && <p>{responseMessage}</p>}
                                        </Col>

                                        <Col md={6} className="image-section">
                                            <img src={contact} alt="Contact Us" className="img-fluid" style={{ borderRadius: '1em' }} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Container>
                    </section>


                    {/* <section className='contact' style={{ position: 'relative', overflow: 'hidden' }}>
                <Container fluid className='p-0 mt-1'>
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                    zIndex: 1, 
                  }} />
                
                  <img src={contactmap} alt="map" className="w-90" style={{ position: 'relative', zIndex: 0 }} />
                </Container>
              </section> */}


                    <section className='offices mt-5'>
                        <Container fluid className='p-0 mt-3'>
                            <h2 className="slide-in" style={{ fontWeight: '600' }}>
                                Kinlong India <span style={{ color: '#2671ae' }}>Offices</span>
                            </h2>
                        </Container>
                    </section>

                    <section className='locations'>
                        <Container className='p-0 mt-1'>
                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.509510743444!2d77.6261284750759!3d12.939215487373128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15d642c1d089%3A0x3947cd84d2fc821b!2sKinlong%20Hardware%20India%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1726550222892!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Bengaluru</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> 1st floor, Akemps Building, 1st Main Rd, Ashwini Layout, Ejipura, Bengaluru, Karnataka 560047
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 9108990181</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3887.4265899270463!2d80.19336137507709!3d13.008484287310354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDAwJzMwLjUiTiA4MMKwMTEnNDUuNCJF!5e0!3m2!1sen!2sin!4v1726651688394!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Chennai</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Anjana Towers,Flat S2 at 5/90-A,Butt Road, St. Thomas Mount,Kancheepuram District, Chennai-600016.
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 7625018175 / +91 8939069306</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3800.517330403258!2d83.3199209!3d17.720247199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3943126b55f923%3A0x27be18a24f60825e!2sKinlong%20Hardware%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1726652172715!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Visakhapatnam</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> # Flat no:- C2, Ville Royal Apartments,3Opposite VUDA Childrens Theatre,Siripuram, Visakhapatnam-530003.
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 7338188226 / +91 8097224390</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60902.23905322049!2d78.3414488!3d17.4410408!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb931147eba201%3A0xe5973982cc1b1f5a!2sKinlong%20Hardware%20India%20Private%20limited!5e0!3m2!1sen!2sin!4v1726652072752!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Hyderabad</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Flat no 512, 4th floor,KTC Illumination, Vittal Rao Nagar,HITEC City, Hyderabad, Telangana-500081
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 7899001161</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.1990655988284!2d76.3030184!3d10.0004093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d230690173d%3A0x8abf7f61c79db1da!2sKinlong%20Hardwares%20India%20Private%20Limited%20-%20Cochin!5e0!3m2!1sen!2sin!4v1726657027067!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Kochi</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Kin Long Hardware (India) Pvt Ltd. 1st Floor, Balakrishna Arcade, Ratna Lane,South Janatha Road, Palarivattom - 682025
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 9342678612 / +91 8129777762</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.1990655988284!2d76.3030184!3d10.0004093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d230690173d%3A0x8abf7f61c79db1da!2sKinlong%20Hardwares%20India%20Private%20Limited%20-%20Cochin!5e0!3m2!1sen!2sin!4v1726657027067!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Calicut</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Kin Long Hardware (India) Pvt Ltd.3.C, Royal luxury Apartments,West Nadakkave, Vandipetta,Vellayil, Kozhikode, Kerala-673011
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 8086702006</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.924272482268!2d73.01574289999999!3d19.1109778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1b69edb1edb%3A0x906ed282d1faf56f!2sKinlong%20Hardware%20India%20Private%20Limited%20-%20Mumbai.!5e0!3m2!1sen!2sin!4v1726653093001!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Mumbai</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> 211, C wing Rupa Solitaire,Sector - 1,Millennium Business Park,Thane Belapur Road,MAHAPE - 400710 Ships Mumbai
                                                {/* <p style={{ color: '#2671ae' }}>Mob - 08097224390 / 022-49701988</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.498682922603!2d73.7604941!3d18.5063536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be7452a8cbbb%3A0xbb1eb639db9d58d6!2sWhistling%20Meadows!5e0!3m2!1sen!2sin!4v1726653431902!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Pune</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Wing C, Flat No. 401, Whistling Meadows Society,Near Marigold Banquet, Bavdhan-Bhugaon, Pune-(411021)412115
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 7304978367 / +91 7506184601</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14735.891704270693!2d88.4596984!3d22.5801159!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275535868eabb%3A0x1e5e8bb43e8d6f2e!2sKIN%20LONG%20HARDWARE%20INDIA%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1726653608588!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Kolkata</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span>Kin Long Hardware (India) Pvt Ltd.DLF GALLERIA,Unit No-DL 1010,10th Floor, Action Area 1 ,Newtown, Kolkata,WestBengal-700156
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 9780068234</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.113346291183!2d85.824608!3d20.295575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909c30dd99285%3A0x97bd0a3da30f325a!2sHARDWARE!5e0!3m2!1sen!2sin!4v1726654519656!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Odisha</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> O-Launch Pad, HIG 45 Infront of Durga Mandap, Bhubaneswar Odisha Bhubaneshwar - 751013
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 9311685902</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14232.929961200609!2d75.7557492!3d26.8961157!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db55a7167987f%3A0xc51ff9816395e934!2sKinlong%20Hardware%20India%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1726654626645!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Jaipur</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> #Ground Floor, Office No G - 08,Metropolis Tower, Near Purani Chungi,Ajmer Road, Jaipur,Rajasthan - 302019.
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 9780068234 / +91 9899080197</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.2845618351207!2d77.0496673!3d28.4709757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19186b528d3b%3A0x9d38876e0dccbd44!2sKinlong%20Hardware%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1726654751400!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Gurgoan</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span>Parshavnath Arcadia Building , 5th Floor ,M.G Road , Sector -14 , Gurugram, Haryana ,PIN-122001 (above MI Mobile Service Center)
                                                {/* <p style={{ color: '#2671ae' }}>Mob - 01244053351 / +91 9899080195</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.631008454067!2d77.32449679999999!3d28.5508087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce51563a6ada1%3A0x525faae9a4170220!2sKinlong%20Hardware%20India%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1726655318027!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Noida</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Kin Long Hardware (India) Pvt Ltd.Unit 815, Astralist (Supernova),Sector 94, Noida,Uttar Pradesh - 201313.
                                                {/* <p style={{ color: '#2671ae' }}>Mob - 01244053351</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.332852540173!2d72.523741!3d23.0115478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84d5839c0649%3A0x9a81876bb77c53c7!2sBLOCK-D%2C%20TITANIUM%20CITY%20CENTER%20BUSINESS%20PARK%2C%20Jodhpur%20Village%2C%20Ahmedabad%2C%20Gujarat%20380015!5e0!3m2!1sen!2sin!4v1726655512801!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Ahmedabad</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> D-1114, Titanium City Center,Nr. Sachin Tower, 100 Ft Road,Anandnagar, Ahmedabad,Gujarat - 380015
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 9724053211 / +91 9512443446</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.7640683944205!2d76.687631!3d30.696913599999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feee29d21900f%3A0x2baab6ce1c6cda86!2sKinlong%20Hardware%20India%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1726655625207!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Mohali</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span>Kin Long Hardware (India) Pvt Ltd.F-468, 1st floor, Phase-8 B, Sector-91,Mohali, Punjab- 160071
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 9780068080 / +91 9311685897</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="d-flex">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1069.6372111244536!2d85.13758927343694!3d25.61101694913722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed59dfe0ac4cef%3A0xff36d85d4504d946!2sDumraon%20Place!5e0!3m2!1sen!2sin!4v1731922369632!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Patna</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Office no. D-217, 2nd floor Dumraon palace, Fraser road, near Dak Bunglow Chauraha, Patna - 80001 Bihar
                                                {/* <p style={{ color: '#2671ae' }}>Mob - 9311685902</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>


                    <section className='wharehouse'>
                        <Container fluid className='p-0 mt-3'>
                            <h2 className="slide-in" style={{ fontWeight: '600' }}>
                                Kinlong India  <span style={{ color: '#2671ae' }}>Warehouse</span>
                            </h2>
                        </Container>
                    </section>

                    <section className='locations'>
                        <Container className='p-0 mt-1'>
                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.5807239615574!2d77.3634064!3d13.1257266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae2f4f7cc85725%3A0x85b71a05eb0d9291!2sKINLONG%20HARDWARE%20NEW%20WAREHOUSE!5e0!3m2!1sen!2sin!4v1726656053933!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Bengaluru Warehouse</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Survey No.48/3,Bommanahalli Village ,Kasaba Hobli, Tumkur Road,Nelamangala Taluk Bangalore Rural,Karnataka - 562123
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 9742243031 / +91 9108990190</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.2719471759124!2d80.2180032!3d13.145238599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5264e9023632d5%3A0xdfc2db88f6872bdd!2s90%2C%20Grand%20Northern%20Trunk%20Rd%2C%20Madhavaram%2C%20Chennai%2C%20Tamil%20Nadu%20600110!5e0!3m2!1sen!2sin!4v1726656199722!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Chennai Warehouse</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Kin Long Hardware (India) Pvt Ltd.Door No.90, G.N.T Road, Ponnammanmedu, Madhavaram, Chennai-600060
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 9944496671</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3510.6632876324143!2d76.84969439999999!3d28.3690278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDIyJzA4LjUiTiA3NsKwNTAnNTguOSJF!5e0!3m2!1sen!2sin!4v1726656319749!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Gurgaon Warehouse</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Kin Long Hardware (India) Pvt Ltd. Regd., 20//7/1,Village Jamalpur, Tehsil Farukhnagar,District Gurgaon, Haryana, 122503.
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 7625018172</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                            <Row className="d-flex justify-content-center">
                                {/* Card 1 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.3826163347308!2d73.0749639!3d19.2657204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bd92ba38c3d1%3A0x339f35d4d0b7d633!2sKinlong%20Hardware%20India%20Mumbai%20Warehouse!5e0!3m2!1sen!2sin!4v1726656597940!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Mumbai Warehouse</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span> Building No. B2 Unit 1 & 2, B4 Unit 10.Bhumi World Industrial Park,Pimplas Village,Bhiwandi, Thane 421302.
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 8291812010</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* Card 2 */}
                                <Col lg={4} md={6} sm={12} className="mb-4 d-flex justify-content-center">
                                    <Card className="contact-card">
                                        <div className="contact-card-iframe">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d58891.277107638445!2d88.2625474!3d22.7020299!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f8856f667875f7%3A0xd1d434cacdff30ae!2sKIN%20LONG%20HARDWARE%20(INDIA)%20PVT%20LTD%20(WAREHOUSE)!5e0!3m2!1sen!2sin!4v1726656728521!5m2!1sen!2sin"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 0 }}
                                                allowFullScreen={true}
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.5em', color: '#000000' }}>Kolkata Warehouse</Card.Title>
                                            <Card.Text style={{ fontSize: '0.8em', }}>
                                                <span style={{ fontWeight: 'bold', color: '#2671ae' }}>Address:</span>Kin Long Hardware (India) Pvt Ltd.Star Battery complex CHAKUNDI DANKUNI HOOGLY West Bengal,Pin,712310, Kolkata.
                                                {/* <p style={{ color: '#2671ae' }}>Mob - +91 7625018172</p> */}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                        </Container>
                    </section>
                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}
                    <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd/" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india/" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/company/kinlong-hardware-india-private-limited-official/?viewAsMember=true" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>
                    </div>
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>

                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>
                </>
            )}
        </div>
    );
};

export default Contact;
