import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaPhone, FaEnvelope } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../home/Header';
import Footer from '../home/Footer';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import emailicon from '../../../images/email.png';
import abbackground from '../../../images/abbackground01.png';
import dimond from '../../../images/our01.png';
import newab from '../../../images/neab01.png';
import newab1 from '../../../images/neab02.png';
import newab2 from '../../../images/neab03.png';
import newab3 from '../../../images/neab04.png';
import infra1 from '../../../images/inf01.png';
import infra2 from '../../../images/inf02.png';
import infra3 from '../../../images/inf03.png';
import Quality01 from '../../../images/abquality01.png';
import twittericon from '../../../images/twitter.png';
import whatsappIcon from '../../../images/whatspp.png';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
// import about from '../../../images/about01.png';
import about from '../../../images/banner01.png';
import about1 from '../../../images/why01.png';
import Infra from '../../../images/infra01.png';
import Quality from '../../../images/quality01.jpg';
import Arrow from './Arrow';

const About = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="boxed_wrapper ltr" style={{ backgroundColor: '#F7F7F7' }}>
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>

                    <Header />

                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '150px 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${abbackground})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        >
                        </div>
                    </div>

                    <section className='ourstory'>
                        <Container className='ourstory-container'>
                            <div className="ourstory-content">
                                <img src={dimond} alt="left image" className="ourstory-image" />
                                <h2 className='ourstory-head'>Our <span style={{ color: '#005EA7' }}>Story</span></h2>
                                <img src={dimond} alt="right image" className="ourstory-image" />
                            </div>
                            <p className='ourstorypara'>Our workforce is attentive to deliver quality; therefore we are serving industry approved designs of Door Lock, Door Fittings, Railings, Embedded Channel, Wall Fittings, Point Fix & Curtain Wall System for all facades structures (spiders, clamps, tension rod, tension cable etc) and much more construction hardware products. Our offered range of high-quality products is manufactured by utilizing carefully selected inventory of raw materials to render beneficial attributes of robust quality construction, durable performance, light weight designs, ease of installation and competitive price bands.</p>

                            <div style={{ marginTop: '2rem' }} className="image-row">
                                <img src={newab} alt="image1" className="image-row-item" />
                                {/* <img src={newab1} alt="image2" style={{ marginLeft: '-11.875rem' }} className="image-row-item" />
                                <img src={newab2} alt="image3" style={{ marginLeft: '-11.875rem' }} className="image-row-item" />
                                <img src={newab3} alt="image4" style={{ marginLeft: '-11.875rem' }} className="image-row-item" /> */}
                            </div>
                        </Container>
                    </section>

                    <section className='about'>
                        <Container className='ourstory-container' style={{ marginTop: '1.25rem' }}>
                            <Row>
                                <Col md={6}>
                                    <div style={{ paddingLeft: '2rem' }}>
                                        <h2 className='whyus-head'>Why <span style={{ color: '#005EA7' }}>Us?</span></h2>
                                        <p className='whyuspara'>Customer Satisfaction is our primary goal, we excel in meeting our performance
                                            with the service and performance expectation of our Esteemed Clients. We
                                            benchmark performance at each stage. Our offered products are widely demanded
                                            the offered assurance of highest industry standards, and owing to the allied support
                                            of leading clientele, we are providing the best quality at the reasonable price.
                                            Besides, we have invested in latest techniques that lowered the production costs and
                                            equip us to present customized designed with the same level of proficiency. </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <img src={about1} alt="Right Side" style={{ width: '100%', maxHeight: '19.625rem' }} />
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className='ourstory'>
                        <Container className='ourstory-container' style={{ marginTop: '1.25rem' }}>
                            <div className="ourstory-content">
                                <img src={dimond} alt="left image" className="ourstory-image" />
                                <h2 className='ourstory-head'>Our <span style={{ color: '#005EA7' }}>Infrastructure</span></h2>
                                <img src={dimond} alt="right image" className="ourstory-image" />
                            </div>
                            <p className='ourstorypara'>Our expertise in customization , manufacturing , higher performance and affordability has resulted in some of the Best Landmark Projects in India such the Meenakshi tech park which is the Tallest Single Cable Vertical Facade in India , The SS railing works of al the new Lulu Malls , Lodha World Towers, DAICEC Mumbai , Reliance Twin Tower, ONGC, Venus Stratum, Omkar 1973,Oberoi Esquire, K Raheja Artesia, Makers Maxity, Indiabulls BLU Towers, Phoenix Market City Mall, , Godrej I & II, Oberoi Commerz I, Commerz II . we are Airport Hardware Experts having executed Mumbai International Airport - T2, Delhi Airport, Port Blair Airport, Cochin Airport, Dehradun Airport, Kolkata International Airport, Bangalore Airport. All airport projects standing the test of time with zero performance issues and ensuring safety.</p>

                            <div className="infraimage-row">
                                <img src={infra1} alt="image1" className="infraimage-row-item" />
                                <img src={infra2} alt="image2" className="infraimage-row-item" />
                                <img src={infra3} alt="image3" className="infraimage-row-item" />
                            </div>
                        </Container>
                    </section>

                    {/* <section className='about mb-5'>
                        <Container className='mt-5'>
                            <Row>
                                <Col md={6} className="d-flex align-items-center">
                                    <div>
                                        <h2>Our <span style={{ color: '#2671ae' }}>Infrastructure</span></h2>
                                        <p className='aboutpara'>Our expertise in customization , manufacturing , higher performance and
                                            affordability has resulted in some of the Best Landmark Projects in India such the
                                            Meenakshi tech park which is the Tallest Single Cable Vertical Facade in India , The
                                            SS railing works of al the new Lulu Malls , Lodha World Towers, DAICEC Mumbai ,
                                            Reliance Twin Tower, ONGC, Venus Stratum, Omkar 1973,Oberoi Esquire, K Raheja
                                            Artesia, Makers Maxity, Indiabulls BLU Towers, Phoenix Market City Mall, , Godrej
                                            I & II, Oberoi Commerz I, Commerz II . we are Airport Hardware Experts having
                                            executed Mumbai International Airport - T2, Delhi Airport, Port Blair Airport,
                                            Cochin Airport, Dehradun Airport, Kolkata International Airport, Bangalore Airport. All airport projects standing the test of time with zero performance issues
                                            and ensuring safety.</p>
                                    </div>
                                </Col>
                                <Col md={6} className="mt-5">
                                    <img src={Infra} alt="Right Side" style={{ width: '100%', borderRadius: '7px' }} />
                                </Col>
                            </Row>
                        </Container>
                    </section> */}
                    <section className='quality'>
                        <Container className='ourstory-container mb-4' style={{ marginTop: '1.25rem' }}>
                            <Row>
                                <Col md={6}>
                                    <img src={Quality01} alt="Right Side" />
                                </Col>
                                <Col md={6} className="d-flex mt-4">
                                    <div>
                                        <h2 className='quality-head'>Quality <span style={{ color: '#005EA7' }}>Assurance</span></h2>
                                        <p className='whyuspara'>We make sure that our business activities are conducted with adherence to
                                            the highest standards of manufacturing. And, we have recruited well-credentialed
                                            quality management officials to assure our clients with the expected performance
                                            and durable standards. We have implemented leading testing devices to verify the
                                            manufacturing standards of each product in terms of its precision, design, and
                                            finish.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>

                    {/* <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd/" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india/" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/company/kinlong-hardware-india-private-limited-official/?viewAsMember=true" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>

                    </div> */}
                    {/* Footer Section */}
                    <section>

                        <Footer />

                    </section>
                </>
            )
            }
        </div >
    );
};

export default About;
