import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaPhone, FaEnvelope, FaArrowRight } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../home/Header';
import Footer from '../home/Footer';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import kinLogo from '../../../images/kinlogo.png';
import Artech from '../../../images/artech01.png';
import Galaxy from '../../../images/Galaxy01.png';
import Galaxy1 from '../../../images/Galaxy02.png';
import Adani from '../../../images/adani01.jpg';
import Cloud from '../../../images/cloud01.jpg';
import Voyage from '../../../images/voyage01.png';
import Bellavista from '../../../images/bellavista01.jpg';
import bb from '../../../images/bb01.jpg';
import flag from '../../../images/indianflag.png';
import emailicon from '../../../images/email.png';
import twittericon from '../../../images/twitter.png';
import footerLogo from '../../../images/kinlongfooter01.png';
import whatsappIcon from '../../../images/whatspp.png';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
import about from '../../../images/banner01.png';
import Arrow from './Arrow';

const ResidentialProjects = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const services = [
        { title: 'Artech Diamond Enclave', imgSrc: Artech, link: 'https://imart.kinlong.in/#/projects/16' },
        { title: 'Galaxy Eleve Maison', imgSrc: Galaxy, link: 'https://imart.kinlong.in/#/projects/16' },
        { title: 'Galaxy Magnum Opus', imgSrc: Galaxy1, link: 'https://imart.kinlong.in/#/projects/16' },
        { title: 'B&B Opulent Spire', imgSrc: bb, link: 'https://imart.kinlong.in/#/projects/17' },
        { title: 'Adani La Marina towers, Ahmedabad', imgSrc: Adani, link: 'https://imart.kinlong.in/#/projects/16' },
        { title: 'Cloud 9, Ahmedabad', imgSrc: Cloud, link: 'https://imart.kinlong.in/#/projects/16' },
        { title: 'Voyage To The Stars, Pune', imgSrc: Voyage, link: 'https://imart.kinlong.in/#/projects/16' },
        { title: 'Prestige Bellavista', imgSrc: Bellavista, link: 'https://imart.kinlong.in/#/projects/16' },
    ];
    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>

                    <Header />

                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '50px 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${about})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        >
                        </div>

                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgb(255 255 255 / 33%)',
                                zIndex: 2,
                            }}
                        ></div>

                        <Container fluid style={{ position: 'relative', zIndex: 3 }}>
                            <Breadcrumb className="breadcrumb mt-5">
                                <h1 style={{ color: '#ffffff', textDecoration: 'none', top: 29 }}>Residential <span style={{ color: '#2671ae' }}>Projects</span></h1>
                            </Breadcrumb>
                        </Container>

                    </div>
                    <section className="service-section pt_70 pb_120 mt-5">
                        <Container fluid>
                            <Row>
                                {/* Sidebar */}
                                <Col lg={4} md={12}>
                                    <Card style={{ textAlign: 'center', borderRadius: '2em', boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)' }}>
                                        <div className="service-sidebar mr_40">
                                            <div className="category-widget mb_40">
                                                <ul className="category-list clearfix" style={{ padding: '20px' }}>
                                                    <li><Link to="/landmarkprojects">Landmark Projects</Link></li>
                                                    <li className="mt-3"><Link to="/commercialprojects">Commercial Projects</Link></li>
                                                    <li className="mt-3"><Link to="/residentialprojects" className="current">Residential Projects <FaArrowRight /></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                                {/* Content Area */}
                                <Col lg={8} md={12}>
                                    <div className="service-details-content">
                                        <Row>
                                            {services.map((service, index) => (
                                                <Col lg={6} md={6} sm={12} className="category-block" key={index}>
                                                    <Card className="service-block-one">
                                                        <div className="inner-box">
                                                            <figure className="image-box image-hov-two">
                                                                <a href={`${service.link}`} target="_blank">
                                                                    <img src={service.imgSrc} alt={`${service.title}`} />
                                                                </a>
                                                                <div className="overlay-image">
                                                                    <div className="lower-content">
                                                                        <h3>
                                                                            <a href={`${service.link}`} target="_blank">{service.title}</a>
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </figure>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>



                    {/* WhatsApp Icon */}
                    <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a>
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>
                    <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd/" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india/" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/company/kinlong-hardware-india-private-limited-official/?viewAsMember=true" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>

                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>
                    </div>
                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>
                </>
            )}
        </div>
    );
};

export default ResidentialProjects;
