// src/router/AppRouter.js

import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../home/Home';
import About from '../home/About';
import Careers from '../home/Careers';
import Contact from '../home/Contact';
import ScrollToTop from '../home/ScrollToTop';
import LandmarkProjects from '../home/LandmarkProjects';
import CommercialProjects from '../home/CommercialProjects';
import ResidentialProjects from '../home/ResidentialProjects';
import Products from '../home/Products';
import DoorControl from '../home/DoorControl';
import InteriorProducts from '../home/InteriorProducts';
import Catalogue from '../home/Catalogue';
import Terms from '../home/Terms';
import PrivacyPolicy from '../home/PrivacyPolicy';
import Posh from '../home/Posh';

const AppRouter = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/landmarkprojects" element={<LandmarkProjects />} />
                <Route path="/commercialprojects" element={<CommercialProjects />} />
                <Route path="/residentialprojects" element={<ResidentialProjects />} />
                <Route path="/products" element={<Products />} />
                <Route path="/doorandcontrol" element={<DoorControl />} />
                <Route path="/interiorproducts" element={<InteriorProducts />} />
                <Route path="/catalogue" element={<Catalogue />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/posh" element={<Posh />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
